import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Once you deploy the crowdfunding platform, will I completely own it?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Yes, once we have programmed, designed, and deployed your DeFi Crowdfunding platform, you will completely own it. You can use it in whichever manner you feel like. You can also change the source code.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Is getting a DeFi Crowdfunding platform built an expensive undertaking?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Please remember that it is a complete platform. It may require lots of weeks of designing, programming, testing and retesting. Nonetheless, we try to make our DeFi Crowdfunding platform development services available to a broad section of clientele. Do send us your requirements and we will let you know exactly how much it may cost you.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Do you have your own development team or you will outsource the DeFi Crowdfunding platform development process?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">We have our own development team. We have our own workspace where our programmers work.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Why are DeFi Crowdfunding platforms becoming popular these days?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">There are multiple reasons. Blockchain technologies are coming of age. More companies, especially financial institutions, are embracing blockchain development. Small enterprises are looking for funds to expand their businesses but they don't want to go through the traditional financial institutions that can be constraining. Crowdfunding is fast emerging as a viable option. Hence, DeFi Crowdfunding platforms are becoming popular these days.
                </div>
              </Accordion.Collapse>
            </div>
          
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection