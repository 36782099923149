import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">DeFi Crowdfunding Platform Development Services</h1>
            <p className="sub-heading">Raise funds through a DeFi platform without third-party involvement.</p>
            <p className="sub-small">Easy onboarding of your ambitious projects. Get initial funding for your project through DeFi crowdfunding.</p>
          </div>
          <div className="quick text-center">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/deficrowdfunding/defi-crowdfunding-platform-development.png" alt="DeFi Crowdfunding Platform development Banner" />
          </div>
          <p className="pharagraph">Establish a direct connection with your investors through DeFi crowdfunding.
          </p>
          <div className="text-center mt-4">
            <ButtonComponent />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection